import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
}                          from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Params }          from '@angular/router';
import {
  DialogButton,
  DialogConfig,
}                          from '~shared/components';
import { Xs2aService }     from './xs2a.service';

export type Xs2aRedirectRequest = {
  queryParams: Params;
}

@Component({
  selector: 'rb-xs2a-redirect-dialog',
  templateUrl: './xs2a-redirect-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Xs2aRedirectDialogComponent implements OnInit {
  createAiarAuthorizationIsLoading$ = this.xs2aService.createAiarAuthorizationIsLoading$;
  aiarAuthorization$ = this.xs2aService.aiarAuthorization$;
  aiarAuthorizationError$ = this.xs2aService.aiarAuthorizationError$;

  dialogConfig: DialogConfig = {
    title: 'APP.XS2A_REDIRECT.TITLE',
    closeable: false,
    buttons: [
      {
        id: DialogButton.OK,
        label: 'APP.BUTTON.CLOSE.LABEL',
        icon: 'cross',
        style: 'secondary',
        autoClose: false,
        disabled$: this.createAiarAuthorizationIsLoading$,
        isLoading$: this.createAiarAuthorizationIsLoading$,
      },
    ],
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public request: Xs2aRedirectRequest,
    private readonly xs2aService: Xs2aService,
  ) {
  }

  get financialInstitutionError(): string | null {
    return this.request.queryParams['error'] ?? null;
  }

  get financialInstitutionErrorDescription(): string | null {
    return this.request.queryParams['error_description'] ?? null;
  }

  ngOnInit(): void {
    if (!this.financialInstitutionError) {
      this.xs2aService.createAiarAuthorization(this.request.queryParams);
    }
  }

  onDialogButtonClick() {
    this.xs2aService.closeXs2aRedirectDialog();
  }
}
