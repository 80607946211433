import { Routes }                  from '@angular/router';
import { Feature }                 from '~domain/enums';
import { AppRoute }                from './app.route.enum';
import { IsabelRedirectComponent } from './isabel/isabel-redirect.component';
import { LoginComponent }          from './login/login.component';
import { MainComponent }           from './main/main.component';
import {
  featureGuard,
  loggedInGuard,
  loggedOutGuard,
}                                  from './route-guards';
import { environmentGuard }        from './route-guards/environment.guard';
import { Xs2aRedirectComponent }   from './xs2a/xs2a-redirect.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [loggedInGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: AppRoute.Clients,
      },
      {
        path: AppRoute.Xs2aRedirect,
        pathMatch: 'full',
        component: Xs2aRedirectComponent,
        canActivate: [featureGuard],
        data: { feature: Feature.XS2A },
      },
      {
        path: AppRoute.IsabelRedirect,
        pathMatch: 'full',
        component: IsabelRedirectComponent,
        canActivate: [featureGuard],
        data: { feature: Feature.ISABEL_AIS },
      },
      {
        path: AppRoute.Dashboard,
        canActivate: [environmentGuard],
        data: { environment: 'develop' },
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: AppRoute.Clients,
        loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule),
      },
      {
        path: AppRoute.Messages,
        loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule),
      },
      {
        path: AppRoute.Contacts,
        loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule),
      },
      {
        path: AppRoute.BankAccounts,
        loadChildren: () => import('./bank-accounts/bank-accounts.module').then(m => m.BankAccountsModule),
      },
      {
        path: AppRoute.Payments,
        canActivate: [featureGuard],
        data: { feature: Feature.ISABEL_PI },
        loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule),
      },
      {
        path: AppRoute.Notifications,
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
      },
      {
        path: AppRoute.Actions,
        loadChildren: () => import('./actions/actions.module').then(m => m.ActionsModule),
      },
      {
        path: AppRoute.Archive,
        loadChildren: () => import('./archive/archive.module').then(m => m.ArchiveModule),
      },
      {
        path: AppRoute.Settings,
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
      },
    ],
  },
  {
    path: AppRoute.Login,
    component: LoginComponent,
    canActivate: [loggedOutGuard],
  },
];
