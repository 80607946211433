<mat-sidenav-container *ngIf="user$ | async" class="h-full bg-primary text-primary">
  <mat-sidenav class="box-content bg-secondary border-medium {{ sideNav.isCollapsed ? 'w-[6rem]' : 'w-[20rem]' }}"
               disableClose="true"
               mode="side"
               opened="true">
    <div class="flex flex-col p-[1rem] h-full">
      <div class="flex justify-center items-center mb-[2rem] h-[4rem]">
        <img alt="roov-logo" class="h-[4rem]" src="assets/img/roov-logo{{ sideNav.isCollapsed ? '-sm' : '' }}.svg" />
      </div>
      <ng-container *ngTemplateOutlet="navList; context: { items: sideNav.items }"></ng-container>
      <mat-nav-list class="py-0 flex flex-col flex-1">
        <mat-list-item (click)="onLogoutClick()" tabindex="0">
          <mat-icon [matTooltipDisabled]="!sideNav.isCollapsed"
                    class="icon-20"
                    matListItemIcon
                    matTooltip="{{ 'APP.SIDENAV.MENU.ACTION.LOGOUT.TOOLTIP' | translate }}"
                    svgIcon="sign-out"></mat-icon>
          <span *ngIf="!sideNav.isCollapsed"
                matListItemTitle>{{ 'APP.SIDENAV.MENU.ACTION.LOGOUT.LABEL' | translate }}</span>
        </mat-list-item>
        <ng-container *ngIf="showIsabel6Link$ | async">
          <mat-divider></mat-divider>
          <a class="flex justify-center items-center gap-x-3 cursor-pointer"
             href="https://www.isabel.eu/"
             rel="noopener"
             tabindex="0"
             target="_blank">
            <img [matTooltipDisabled]="!sideNav.isCollapsed"
                 alt="isabel-logo"
                 class="{{ sideNav.isCollapsed ? 'h-6' : 'h-10' }}"
                 matTooltip="{{ 'APP.SIDENAV.MENU.ACTION.ISABEL.TOOLTIP' | translate }}"
                 src="assets/img/isabel-6-logo{{ sideNav.isCollapsed ? '-sm' : '' }}.svg" />
            <mat-icon *ngIf="!sideNav.isCollapsed" class="icon-15 icon-secondary" svgIcon="external-link"></mat-icon>
          </a>
        </ng-container>
        <div class="flex-1"></div>
        <div *ngrxLet="colorMode$ as colorMode" class="flex flex-wrap justify-center gap-[10px] py-[0.5rem]">
          <mat-icon (click)="setColorMode('light')"
                    [ngClass]="colorMode === 'light' ? 'icon-brand' : 'icon-secondary hover:icon-primary'"
                    class="icon-20 cursor-pointer"
                    matListItemIcon
                    matTooltip="{{ 'NAVBAR.COLOR_MODE.light' | translate }}"
                    svgIcon="sun"></mat-icon>
          <mat-icon (click)="setColorMode('dark')"
                    [ngClass]="colorMode === 'dark' ? 'icon-brand' : 'icon-secondary hover:icon-primary'"
                    class="icon-20 cursor-pointer"
                    matListItemIcon
                    matTooltip="{{ 'NAVBAR.COLOR_MODE.dark' | translate }}"
                    svgIcon="moon"></mat-icon>
          <mat-icon (click)="setColorMode(null)"
                    [ngClass]="colorMode === null ? 'icon-brand' : 'icon-secondary hover:icon-primary'"
                    class="icon-20 cursor-pointer"
                    matListItemIcon
                    matTooltip="{{ 'NAVBAR.COLOR_MODE.system' | translate }}"
                    svgIcon="system"></mat-icon>
        </div>
        <mat-divider></mat-divider>
        <mat-list-item (click)="sideNav.toggle()" tabindex="0">
          <mat-icon [matTooltipDisabled]="!sideNav.isCollapsed"
                    [svgIcon]="sideNav.isCollapsed ? 'chevron-right' : 'chevron-left'"
                    class="icon-20"
                    matListItemIcon
                    matTooltip="{{ 'APP.SIDENAV.MENU.ACTION.COLLAPSE.TOOLTIP' | translate }}"></mat-icon>
          <span *ngIf="!sideNav.isCollapsed"
                matListItemTitle>{{ 'APP.SIDENAV.MENU.ACTION.COLLAPSE.LABEL' | translate }}</span>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="{{ sideNav.isCollapsed ? 'ml-[6rem]' : 'ml-[20rem]' }}">
    <div class="h-full max-w-[1280px] mx-auto">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #navList let-items="items">
  <mat-nav-list class="py-0">
    <ng-container *ngFor="let item of items">
      <ng-container *ngIf="(item.hidden$ | async) !== true">
        <mat-divider *ngIf="item.type === 'divider'"></mat-divider>
        <a *ngIf="item.type === 'nav-item'"
           [class.collapsed]="sideNav.isCollapsed"
           [matBadge]="(item.badge?.value | async) || null"
           [routerLink]="item.route"
           mat-list-item
           matBadgeColor="warn"
           matBadgeSize="small"
           routerLinkActive="mdc-list-item--activated">
          <mat-icon [matTooltipDisabled]="!sideNav.isCollapsed"
                    [matTooltip]="'APP.SIDENAV.' + item.key + '.TOOLTIP' | translate"
                    [ngClass]="item.isGroupItem ? 'icon-20 p-[0.25rem]' : 'icon-20'"
                    [svgIcon]="item.icon"
                    aria-hidden="false"
                    matListItemIcon></mat-icon>
          <span *ngIf="!sideNav.isCollapsed"
                [class.text-sm]="item.isGroupItem"
                matListItemTitle>{{ 'APP.SIDENAV.' + item.key + '.LABEL' | translate }}</span>
        </a>
        <ng-container *ngIf="item.type === 'group'">
          <mat-list-item (click)="item.toggle()">
            <mat-icon [matTooltipDisabled]="!sideNav.isCollapsed"
                      [matTooltip]="'APP.SIDENAV.' + item.key + '.TOOLTIP' | translate"
                      [svgIcon]="item.icon"
                      aria-hidden="false"
                      class="icon-20"
                      matListItemIcon></mat-icon>
            <span *ngIf="!sideNav.isCollapsed" class="flex justify-between items-center" matListItemTitle>
              {{ 'APP.SIDENAV.' + item.key + '.LABEL' | translate }}
              <div class="flex-1"></div>
              <mat-icon [svgIcon]="item.isCollapsed ? 'chevron-right' : 'chevron-down'" class="icon-15"></mat-icon>
            </span>
          </mat-list-item>
          <ng-container *ngIf="!item.isCollapsed">
            <ng-container *ngTemplateOutlet="navList; context: { items: item.items }"></ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </mat-nav-list>
</ng-template>
