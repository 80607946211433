import { HttpClient }  from '@angular/common/http';
import {
  Inject,
  Injectable,
}                      from '@angular/core';
import { Params }      from '@angular/router';
import {
  AiarAuthorization,
  AiarInfo,
  FinancialInstitution,
}                      from '~domain/types';
import { ENVIRONMENT } from '../app.constants';

@Injectable({
  providedIn: 'root',
})
export class Xs2aApiService {

  constructor(
    @Inject(ENVIRONMENT) private readonly environment: Environment,
    private readonly http: HttpClient,
  ) {
  }

  fetchFinancialInstitutions() {
    return this.http.get<FinancialInstitution[]>(`${this.environment.api}/xs2a-financial-institutions`);
  }

  fetchFinancialInstitution(id: string) {
    return this.http.get<FinancialInstitution>(`${this.environment.api}/xs2a-financial-institutions/${id}`);
  }

  fetchAiarInfo(financialInstitutionId: string) {
    return this.http.get<AiarInfo>(`${this.environment.api}/xs2a-financial-institutions/${financialInstitutionId}/aiar-info`);
  }

  createAiar(financialInstitutionId: string, forImport: boolean, customerReference?: string, ibans?: string[]) {
    return this.http.post(
      `${this.environment.api}/aiars`,
      { financialInstitutionId, forImport, customerReference, ibans },
      { responseType: 'text' },
    );
  }

  createAiarAuthorization(queryParams: Params) {
    return this.http.post<AiarAuthorization>(`${this.environment.api}/aiar-authorizations`, { queryParameters: queryParams });
  }
}
