import { HttpClient }  from '@angular/common/http';
import {
  Inject,
  Injectable,
}                      from '@angular/core';
import { ENVIRONMENT } from '~app/app.constants';
import {
  Beneficiary,
  CreditorRemittanceInformationType,
  PaymentAccount,
  PaymentData,
  SimpleClient,
}                      from '~domain/types';
import {
  datesToStrings,
  removeEmptyStrings,
}                      from '~utils/common';

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {

  constructor(
    @Inject(ENVIRONMENT)
    private readonly environment: Environment,
    private readonly http: HttpClient,
  ) {
  }

  fetchClients() {
    return this.http.get<SimpleClient[]>(`${this.environment.api}/clients-to-link`);
  }

  fetchBeneficiaries(clientId: string) {
    return this.http.get<Beneficiary[]>(`${this.environment.api}/clients/${clientId}/beneficiaries`);
  }

  fetchAccounts() {
    return this.http.get<PaymentAccount[]>(`${this.environment.api}/payment-accounts`);
  }

  addPayment(paymentData: PaymentData) {
    const { clientId, ...payment } = paymentData;
    const payments = [this.createPaymentRequestBody(payment)];
    return this.http.post<void>(`${this.environment.api}/clients/${clientId}/batch/payments`, { payments });
  }

  updatePayment(paymentData: PaymentData) {
    const { id, ...payment } = paymentData;
    return this.http.put<void>(`${this.environment.api}/payments/${id}`, this.createPaymentRequestBody(payment));
  }

  updateBeneficiary(beneficiaryId: string, clientId: string, beneficiary: Beneficiary) {
    return this.http.put<void>(`${this.environment.api}/clients/${clientId}/beneficiaries/${beneficiaryId}`, beneficiary);
  }

  addBeneficiary(clientId: string, beneficiary: Beneficiary) {
    return this.http.post<void>(`${this.environment.api}/clients/${clientId}/beneficiaries`, beneficiary);
  }

  deleteBeneficiary(beneficiaryId: string, clientId: string) {
    return this.http.delete<void>(`${this.environment.api}/clients/${clientId}/beneficiaries/${beneficiaryId}`);
  }

  private createPaymentRequestBody(paymentData: PaymentData) {
    const payment = removeEmptyStrings(datesToStrings(paymentData));
    const remittanceInformationType = payment.remittanceInformation ? payment.remittanceInformationType : CreditorRemittanceInformationType.unstructured;
    return {
      ...payment,
      remittanceInformationType,
    };
  }
}
