import {
  Observable,
  of,
}                from 'rxjs';
import { using } from '~utils/common';

export type Badge = { value: Observable<number> };

const __parent__ = Symbol('__parent__');

abstract class Item {
  protected [__parent__]?: Group;

  protected constructor(
    readonly type: 'nav-item' | 'group' | 'divider',
    readonly hidden$: Observable<boolean> = of(false),
  ) {
  }

  get isGroupItem(): boolean {
    return this[__parent__] !== undefined;
  }
}

abstract class LabelItem extends Item {
  protected constructor(
    override readonly type: 'nav-item' | 'group' | 'divider',
    protected readonly _key: string,
    readonly icon: string,
    override readonly hidden$: Observable<boolean>,
  ) {
    super(type, hidden$);
  }

  get key(): string {
    return using(this[__parent__]?.key, key => `${key}.${this._key}`) || this._key;
  }
}

class NavItem extends LabelItem {
  constructor(
    icon: string,
    readonly route: string[],
    readonly badge?: Badge,
    override readonly hidden$: Observable<boolean> = of(false),
  ) {
    super(
      'nav-item',
      route
        .map(route => `MENU.ITEM.${route}`)
        .join('.')
        .replace('-', '_')
        .toUpperCase(),
      icon,
      hidden$,
    );
  }
}

class Group extends LabelItem {
  readonly items: Item[] = [];

  constructor(
    icon: string,
    label: string,
    override readonly hidden$: Observable<boolean> = of(false),
  ) {
    super('group', `MENU.ITEM.${label}`.toUpperCase(), icon, hidden$);
  }

  private _isCollapsed = true;

  get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  add(...items: Item[]) {
    this.items.push(...items.map(item => Object.defineProperty(item, __parent__, { value: this })));
    return this;
  }

  toggle() {
    this._isCollapsed = !this._isCollapsed;
  }
}

class Divider extends Item {
  constructor(
    override readonly hidden$: Observable<boolean> = of(false),
  ) {
    super('divider', hidden$);
  }
}

export class SideNav {
  private constructor(readonly items: Item[]) {
  }

  private _isCollapsed = false;

  get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  static build(...items: Item[]): SideNav {
    return new SideNav(items);
  }

  static item(
    config: { route: string | string[]; icon: string; badge?: Badge, hidden$?: Observable<boolean> },
  ): NavItem {
    const { route, icon, badge, hidden$ } = config;
    return new NavItem(icon, Array.isArray(route) ? route : [route], badge, hidden$);
  }

  static group(
    config: { label: string; icon: string, hidden$?: Observable<boolean> },
  ): Group {
    const { icon, label, hidden$ } = config;
    return new Group(icon, label, hidden$);
  }

  static divider(config: { hidden$?: Observable<boolean> }): Divider {
    const { hidden$ } = config;
    return new Divider(hidden$);
  }

  toggle() {
    this._isCollapsed = !this._isCollapsed;
  }
}
