<form [formGroup]="form">
  <div *ngIf="aiarInfo" class="flex flex-col gap-y-[4rem] pb-[1rem]">
    <div *ngIf="aiarInfo.customerReferenceRequired" class="flex flex-col gap-y-[0.5rem] items-start">
      <div>{{ 'APP.XS2A.CUSTOMER_REFERENCE.LABEL' | translate }} <span class="text-brand">*</span></div>
      <div class="text-sm text-secondary my-[0.5rem]">{{ 'APP.XS2A.CUSTOMER_REFERENCE.INFO' | translate : { bank: financialInstitution?.name } }}</div>
      <mat-form-field [class.no-error]="!validatorService.errorMatches(form.controls.customerReference, errorKey$ |
      async)">
        <input [placeholder]="'APP.XS2A.CUSTOMER_REFERENCE.LABEL' | translate"
               formControlName="customerReference"
               matInput
               required
               type="text">
      </mat-form-field>
    </div>
    <div class="flex flex-col gap-y-[0.5rem] content-start items-start">
      <div>{{ 'APP.XS2A.IBANS.LABEL' | translate }}</div>
      <div class="text-sm text-secondary my-[0.5rem]">{{ 'APP.XS2A.IBANS.INFO' | translate }}</div>
      <div *ngFor="let iban of form.controls.ibans.controls; let i = index"
           class="flex gap-x-[0.5rem] items-center">
        <mat-form-field>
          <input [formControl]="iban"
                 [placeholder]="'APP.XS2A.IBANS.PLACEHOLDER' | translate"
                 matInput
                 rbIban
                 type="text" />
        </mat-form-field>
        <button (click)="removeIban(i)" class="button-secondary" mat-icon-button>
          <mat-icon class="icon-15" svgIcon="cross"></mat-icon>
        </button>
      </div>
      <button (click)="addIban()" class="button-primary mt-[0.5rem]" mat-button>
        <mat-icon svgIcon="plus"></mat-icon>
        <span>{{ 'APP.XS2A.IBANS.ADD' | translate }}</span>
      </button>
    </div>
    <div *ngIf="aiarInfo.activeBankAccounts.length > 0"
         class="flex flex-col gap-y-[0.5rem] content-start items-start">
      <div>{{ 'APP.XS2A.ACTIVE_ACCOUNTS.LABEL' | translate }}</div>
      <div class="text-sm text-secondary my-[0.5rem]">{{ 'APP.XS2A.ACTIVE_ACCOUNTS.INFO' | translate }}</div>
      <ul class="text-sm list-outside ml-[0.5rem]">
        <li *ngFor="let bankAccount of aiarInfo.activeBankAccounts">
          {{ bankAccount.iban | split: { size: 4 } }}
          ({{ 'ENUM.BANK_ACCOUNT_TYPE.' + (bankAccount.type || 'BASE') + '.LABEL' | translate }})
        </li>
      </ul>
    </div>
  </div>
</form>
