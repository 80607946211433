<rb-dialog (buttonClick)="onDialogButtonClick()" [config]="dialogConfig">
  <div *ngIf="(createAiarAuthorizationIsLoading$ | async) === false" class="flex flex-col gap-y-[4rem] items-center">
    <div class="flex flex-col gap-y-[1rem] items-center">
      <ng-container *ngIf="financialInstitutionError">
        <mat-icon class="icon-40 icon-negative" svgIcon="cross"></mat-icon>
        <div class="font-bold">{{ 'APP.XS2A_REDIRECT.FI_ERROR.TITLE' | translate }}</div>
      </ng-container>
      <ng-container *ngIf="(aiarAuthorizationError$ | async) === true">
        <mat-icon class="icon-40 icon-negative" svgIcon="cross"></mat-icon>
        <div class="font-bold">{{ 'APP.XS2A_REDIRECT.AIAR_AUTH_ERROR.TITLE' | translate }}</div>
      </ng-container>
      <ng-container *ngIf="aiarAuthorization$ | async">
        <mat-icon class="icon-40 icon-brand" svgIcon="check"></mat-icon>
        <div class="font-bold">{{ 'APP.XS2A_REDIRECT.AIAR_AUTH.TITLE' | translate }}</div>
      </ng-container>
    </div>
    <div class="grid grid-cols-2 gap-x-[4rem] w-full">
      <rb-form-section class="h-[40rem]">
        <ng-container *ngIf="financialInstitutionError">
          <div>{{ 'APP.XS2A_REDIRECT.FI_ERROR.NO_AUTH' | translate }}</div>
          <div class="mt-[4rem]">{{ 'APP.XS2A_REDIRECT.FI_ERROR.INFO' | translate }}</div>
          <div class="font-bold mt-[2rem]">{{ 'APP.XS2A_REDIRECT.FI_ERROR.ERROR' | translate }}</div>
          <div>{{ financialInstitutionError }}</div>
          <ng-container *ngIf="financialInstitutionErrorDescription">
            <div class="font-bold mt-[2rem]">{{ 'APP.XS2A_REDIRECT.FI_ERROR.ERROR_DESCRIPTION' | translate }}</div>
            <div>{{ financialInstitutionErrorDescription }}</div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="(aiarAuthorizationError$ | async) === true">
          <div class="whitespace-pre-wrap">{{ 'APP.XS2A_REDIRECT.AIAR_AUTH_ERROR.INFO' | translate }}</div>
        </ng-container>
        <ng-container *ngIf="aiarAuthorization$ | async as aiarAuthorization">
          <div>{{ 'APP.XS2A_REDIRECT.AIAR_AUTH.ACCOUNTS' | translate }}</div>
          <ul class="ml-[0.5rem] mt-[0.5rem] text-sm">
            <li *ngFor="let iban of aiarAuthorization.ibans">{{ iban | split: { size: 4 } }}</li>
          </ul>
        </ng-container>
      </rb-form-section>
      <rb-form-section class="h-[40rem]">
        <ng-container *ngIf="financialInstitutionError">
          <div class="flex gap-x-[0.5rem] items-center text-sm">
            <mat-icon class="icon-15 icon-brand" svgIcon="question"></mat-icon>
            <div class="font-bold">{{ 'APP.XS2A_REDIRECT.FI_ERROR.WHAT_NOW.TITLE' | translate }}</div>
          </div>
          <ul class="ml-[0.5rem] mt-[0.5rem] text-sm">
            <li>{{ 'APP.XS2A_REDIRECT.FI_ERROR.WHAT_NOW.INFO_1' | translate }}</li>
            <li>{{ 'APP.XS2A_REDIRECT.FI_ERROR.WHAT_NOW.INFO_2' | translate }}</li>
            <li>{{ 'APP.XS2A_REDIRECT.FI_ERROR.WHAT_NOW.INFO_3' | translate }}</li>
            <li>{{ 'APP.XS2A_REDIRECT.FI_ERROR.WHAT_NOW.INFO_4' | translate }}</li>
          </ul>
        </ng-container>
        <ng-container *ngIf="(aiarAuthorizationError$ | async) === true">
          <div class="flex gap-x-[0.5rem] items-center text-sm">
            <mat-icon class="icon-15 icon-brand" svgIcon="question"></mat-icon>
            <div class="font-bold">{{ 'APP.XS2A_REDIRECT.AIAR_AUTH_ERROR.WHAT_NOW.TITLE' | translate }}</div>
          </div>
          <ul class="ml-[0.5rem] mt-[0.5rem] text-sm">
            <li>{{ 'APP.XS2A_REDIRECT.AIAR_AUTH_ERROR.WHAT_NOW.INFO_1' | translate }}</li>
            <li>{{ 'APP.XS2A_REDIRECT.AIAR_AUTH_ERROR.WHAT_NOW.INFO_2' | translate }}</li>
          </ul>
        </ng-container>
        <ng-container *ngIf="aiarAuthorization$ | async">
          <div>{{ 'APP.XS2A_REDIRECT.AIAR_AUTH.SYNCING' | translate }}</div>
          <div class="flex gap-x-[0.5rem] items-center mt-[4rem] text-sm">
            <mat-icon class="icon-15 icon-brand" svgIcon="question"></mat-icon>
            <div class="font-bold">{{ 'APP.XS2A_REDIRECT.AIAR_AUTH.IMPORTANT.TITLE' | translate }}</div>
          </div>
          <ul class="ml-[0.5rem] mt-[0.5rem] text-sm">
            <li>{{ 'APP.XS2A_REDIRECT.AIAR_AUTH.IMPORTANT.INFO_1' | translate }}</li>
            <li>{{ 'APP.XS2A_REDIRECT.AIAR_AUTH.IMPORTANT.INFO_2' | translate }}</li>
          </ul>
        </ng-container>
      </rb-form-section>
    </div>
  </div>
  <div *ngIf="(createAiarAuthorizationIsLoading$ | async) === true" class="flex items-center h-[51rem]">
    <div class="flex flex-col items-center gap-y-[1rem] w-full">
      <mat-spinner diameter="40"></mat-spinner>
      <div class="font-bold">{{ 'APP.XS2A_REDIRECT.LOADING' | translate }}</div>
    </div>
  </div>
</rb-dialog>
