export enum AppRoute {
  Login = 'login',
  Dashboard = 'dashboard',
  Clients = 'clients',
  Messages = 'messages',
  Contacts = 'contacts',
  BankAccounts = 'bank-accounts',
  Payments = 'payments',
  Notifications = 'notifications',
  Actions = 'actions',
  Archive = 'archive',
  Settings = 'settings',
  Xs2aRedirect = 'accounts/add',
  IsabelRedirect = 'isabel/init',
}
