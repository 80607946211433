import { Directive }        from '@angular/core';
import {
  FormGroup,
  NonNullableFormBuilder,
}                           from '@angular/forms';
import {
  distinctUntilChanged,
  map,
  startWith,
}                           from 'rxjs';
import { ValidatorService } from '~app/core/validators';

@Directive()
export abstract class RbDialogForm {

  form: FormGroup = this.fb.group({});

  constructor(protected readonly fb: NonNullableFormBuilder,
              protected readonly validatorService: ValidatorService) {

  }

  get formStatus$() {
    return this.form.statusChanges.pipe(
      startWith(this.form.status),
    );
  }

  get errorKey$() {
    return this.formStatus$?.pipe(
      map(() => this.validatorService.firstErrorKey(this.form)),
      distinctUntilChanged(),
    );
  }

  get submitDisabled$() {
    return this.formStatus$.pipe(
      map(() => !this.form.valid),
      distinctUntilChanged(),
    );
  }
}

