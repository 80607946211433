import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
}                           from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  NG_ASYNC_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
}                           from '@angular/forms';
import {
  AiarInfo,
  FinancialInstitution,
  Xs2aDetailedFormData,
}                           from '~domain/types';
import { RbForm }           from '~shared/directives';
import { CustomValidators } from '../core/validators';

@Component({
  selector: 'rb-xs2a-detailed',
  templateUrl: './xs2a-detailed.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Xs2aDetailedComponent),
      multi: true,
    },
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => Xs2aDetailedComponent),
      multi: true,
    },
  ],
})
export class Xs2aDetailedComponent extends RbForm {
  @Input({ required: true }) financialInstitution?: FinancialInstitution;
  @Input({ required: true }) aiarInfo?: AiarInfo;

  override form = this.fb.group(
    {
      customerReference: this.fb.control(''),
      ibans: this.fb.array<string>([]),
    },
    { validators: [this.ibansValidator] },
  );

  private get ibansValidator(): ValidatorFn {
    const key = 'required';
    const errors: ValidationErrors = { required: true };

    return (group: AbstractControl) => {
      const array = group.get('ibans') as FormArray<FormControl<string>>;
      const ibans = array.value;

      if (array.controls.some(control => control.errors && !control.errors[key])) {
        // Another error with higher priority is already present
        array.controls.forEach(control => {
          if (control.errors && control.errors[key]) {
            control.setErrors(null);
          }
        });
        return null;
      }

      if (ibans.every(iban => iban.length === 0) && (this.aiarInfo?.activeBankAccounts ?? []).length === 0) {
        array.controls.forEach((control: AbstractControl) => control.setErrors(errors));
        return errors;
      }
      array.controls.forEach((control: AbstractControl) => control.setErrors(null));
      return null;
    };
  }

  override writeValue(data: Xs2aDetailedFormData) {
    this.form.controls.customerReference.setValue(data.customerReference);
    this.form.controls.ibans.clear();
    data.ibans.forEach((value) => this.addIban(value));
    if (this.form.controls.ibans.length === 0) {
      this.addIban();
    }
  }

  addIban(value?: string) {
    this.form.controls.ibans.push(this.fb.control(
      value ?? '',
      { asyncValidators: [CustomValidators.ibanValidator(this.validatorService)] },
    ));
  }

  removeIban(index: number) {
    this.form.controls.ibans.removeAt(index);
    if (this.form.controls.ibans.length === 0) {
      this.addIban();
    }
  }
}
