import { NgIf }            from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
}                          from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule }   from '@angular/material/card';
import { MatIconModule }   from '@angular/material/icon';
import { MatListModule }   from '@angular/material/list';
import {
  MatMenuModule,
  MatMenuTrigger,
}                          from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'rb-filter',
  templateUrl: './filter.component.html',
  imports: [
    MatIconModule,
    NgIf,
    MatListModule,
    MatButtonModule,
    MatMenuModule,
    TranslateModule,
    MatCardModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent {

  @Input({ required: true })
  label: string = '';
  @Input()
  labelShort?: string;
  @Input()
  value?: string;
  @Input()
  disabled = false;
  @Input()
  filterValid = true;
  @Output()
  filterSet = new EventEmitter<void>();
  @Output()
  filterCleared = new EventEmitter<void>();
  @ViewChild(MatMenuTrigger) matMenuTrigger?: MatMenuTrigger;

  setFilter() {
    this.filterSet.emit();
    this.matMenuTrigger?.closeMenu();
  }

  unsetFilter() {
    this.filterCleared.emit();
  }

}
