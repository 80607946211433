<ng-container *ngIf="tabs">
  <nav [disableRipple]="true" [mat-stretch-tabs]="false" [tabPanel]="tabPanel" mat-tab-nav-bar>
    <ng-container *ngFor="let tab of tabs">
      <a #rla="routerLinkActive"
         *ngIf="(tab.hidden$ | async) !== true"
         [active]="rla.isActive"
         [disabled]="!!tab.disabled"
         [routerLink]="tab.route$ | async"
         mat-tab-link
         routerLinkActive>
        {{ tab.label | translate }}
        <mat-spinner *ngIf="tab.isLoading$ | async"
                     class="spinner-brand absolute right-[-2.5rem]"
                     diameter="20"></mat-spinner>
      </a>
    </ng-container>
  </nav>
  <mat-tab-nav-panel #tabPanel class="block h-[calc(100%-31px)] py-[1rem] px-[0.5rem] mx-[-0.5rem] overflow-auto">
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</ng-container>
