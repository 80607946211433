export type AiarInfo = {
  customerReferenceRequired: boolean,
  activeBankAccounts: AiarInfoBankAccount[],
  isabelBankAccounts: AiarInfoBankAccount[],
};

export type AiarInfoBankAccount = {
  iban: string,
  type: string,
};
