import { Injectable }   from '@angular/core';
import { Store }        from '@ngrx/store';
import { map }          from 'rxjs';
import { Feature }      from '~domain/enums';
import { selectOffice } from '../state/app.selectors';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {

  constructor(
    private readonly store: Store,
  ) {
  }

  officeHasFeature(feature: Feature) {
    return this.store.select(selectOffice).pipe(
      map(office => office?.features.includes(feature) ?? false),
    );
  }

  officeDoesNotHaveFeature(feature: Feature) {
    return this.officeHasFeature(feature).pipe(map(value => !value));
  }
}
