import { HttpClient }  from '@angular/common/http';
import {
  Inject,
  Injectable,
}                      from '@angular/core';
import { ENVIRONMENT } from '../app.constants';

@Injectable({
  providedIn: 'root',
})
export class IsabelApiService {

  constructor(
    @Inject(ENVIRONMENT) private readonly environment: Environment,
    private readonly http: HttpClient,
  ) {
  }

  createIsabelAuthorization(code: string) {
    return this.http.put<void>(`${this.environment.api}/isabel/authorization-codes/${code}`, {});
  }
}
