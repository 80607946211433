import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
}                          from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Params }          from '@angular/router';
import {
  DialogButton,
  DialogConfig,
}                          from '~shared/components';
import { IsabelService }   from './isabel.service';

export type IsabelRedirectRequest = {
  queryParams: Params;
}

@Component({
  selector: 'rb-isabel-redirect-dialog',
  templateUrl: './isabel-redirect-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsabelRedirectDialogComponent implements OnInit {

  createIsabelAuthorizationIsLoading$ = this.isabelService.createIsabelAuthorizationIsLoading$;
  isabelAuthorizationError$ = this.isabelService.isabelAuthorizationError$;

  dialogConfig: DialogConfig = {
    title: 'APP.ISABEL_REDIRECT.TITLE',
    closeable: false,
    buttons: [
      {
        id: DialogButton.OK,
        label: 'APP.BUTTON.CLOSE.LABEL',
        icon: 'cross',
        style: 'secondary',
        autoClose: false,
        disabled$: this.createIsabelAuthorizationIsLoading$,
        isLoading$: this.createIsabelAuthorizationIsLoading$,
      },
    ],
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public request: IsabelRedirectRequest,
    private readonly isabelService: IsabelService,
  ) {
  }

  get isabelError(): string | null {
    return this.request.queryParams['error'] ?? null;
  }

  get isabelErrorDescription(): string | null {
    return this.request.queryParams['errorDescription'] ?? null;
  }

  ngOnInit(): void {
    if (!this.isabelError) {
      this.isabelService.createIsabelAuthorization(this.request.queryParams['code']);
    }
  }

  onDialogButtonClick() {
    this.isabelService.closeIsabelRedirectDialog();
  }

}
