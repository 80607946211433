<rb-dialog (buttonClick)="onDialogButtonClick($event)" [config]="dialogConfig">
  <form [formGroup]="form" class="flex flex-col gap-y-[4rem]">
    <rb-form-section [title]="'APP.CLIENTS.ADD_CLIENT_DIALOG.CONTENT.INFO.HEADER' | translate">
      <rb-edit-personal-info [defaultLanguage]="defaultLanguage$ | async"
                             [errorKey$]="errorKey$"
                             formControlName="personalInfoData"></rb-edit-personal-info>
    </rb-form-section>
    <rb-form-section [error]="(typeInvalid$ | async) === true"
                     [required]="true"
                     [title]="'APP.CLIENTS.ADD_CLIENT_DIALOG.CONTENT.TYPE.HEADER' | translate">
      <mat-radio-group class="flex" formControlName="type">
        <div *ngFor="let option of clientTypeOptions$ | async"
             [ngClass]="form.controls.type.value === option ? 'text-primary' : 'text-tertiary'"
             class="flex-1 flex flex-col items-center">
          <mat-radio-button [value]="option" class="vertical" labelPosition="before">
            <div class="flex flex-col items-center gap-y-[1rem] hover:cursor-pointer">
              <mat-icon [class.icon-brand]="form.controls.type.value === option"
                        [class.icon-tertiary]="form.controls.type.value !== option"
                        [svgIcon]="'ENUM.CLIENT_TYPE.' + option + '.ICON' | translate"
                        class="icon-40"></mat-icon>
              <div [class.text-secondary]="form.controls.type.value !== option">
                {{ 'ENUM.CLIENT_TYPE.' + option + '.LABEL' | translate }}
              </div>
            </div>
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </rb-form-section>
  </form>
</rb-dialog>
