import { inject } from '@angular/core';
import {
  CanActivateFn,
  Router,
}                 from '@angular/router';
import {
  ACCESS_TOKEN,
  LOCAL_STORAGE,
}                 from '../app.constants';

export const loggedOutGuard: CanActivateFn = () => {
  const localStorage = inject(LOCAL_STORAGE);
  const router = inject(Router);

  const loggedOut = !localStorage.getItem(ACCESS_TOKEN);
  if (!loggedOut) {
    router.navigate(['/']);
  }
  return loggedOut;
};
