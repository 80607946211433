import { NgIf }               from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
}                             from '@angular/core';
import { MatIcon }            from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'rb-form-section',
  standalone: true,
  imports: [NgIf, MatIcon, MatProgressSpinner],
  templateUrl: './form-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSectionComponent {
  @Input()
  title?: string;

  @Input()
  icon?: string;

  @Input()
  isLoading?: boolean;

  @Input()
  background: boolean = true;

  @Input()
  border: boolean = true;

  @Input()
  scroll: boolean = false;

  @Input()
  required: boolean = false;

  @Input()
  error: boolean = false;
}
