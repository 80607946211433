import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';

@Component({
  selector: 'rb-xs2a-redirect',
  templateUrl: './xs2a-redirect.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Xs2aRedirectComponent {
}
