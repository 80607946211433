import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';

@Component({
  selector: 'rb-isabel-redirect',
  templateUrl: './isabel-redirect.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsabelRedirectComponent {
}
