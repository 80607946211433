import {
  AccountUser,
  AiarAuthorization,
  AiarInfo,
  Beneficiary,
  FinancialInstitution,
  Office,
  PaymentAccount,
  SimpleClient,
} from '~domain/types';

export type AppState = {
  colorMode: 'dark' | 'light' | null;
  maintenanceMode: boolean;
  accountUser: AccountUser | null;
  clientBadgeCount: number;
  notificationBadgeCount: number;
  paymentBadgeCount: number;
  unlinkedAccountsBadgeCount: number;
  accountsWithWarningBadgeCount: number;
  office: Office | null;
  createClientIsLoading: boolean;
  documentUpdating: boolean;
  documentDownloadingId: string | null;
  paymentAccounts: PaymentAccount[];
  paymentClients: SimpleClient[];
  beneficiaries: Beneficiary[];
  beneficiariesLoading: boolean;
  paymentInfoLoading: boolean
  paymentSaving: boolean;
  beneficiaryEditing: boolean;
  xs2aInfoIsLoading: boolean;
  xs2aFinancialInstitutions: FinancialInstitution[];
  aiarInfoIsLoading: boolean;
  aiarInfo: AiarInfo | null;
  createAiarIsLoading: boolean;
  createAiarAuthorizationIsLoading: boolean;
  aiarAuthorization: AiarAuthorization | null;
  aiarAuthorizationError: boolean;
  createIsabelAuthorizationIsLoading: boolean;
  isabelAuthorizationError: boolean;
};

export const initialState: AppState = {
  colorMode: null,
  maintenanceMode: false,
  accountUser: null,
  clientBadgeCount: 0,
  notificationBadgeCount: 0,
  paymentBadgeCount: 0,
  unlinkedAccountsBadgeCount: 0,
  accountsWithWarningBadgeCount: 0,
  office: null,
  createClientIsLoading: false,
  documentUpdating: false,
  documentDownloadingId: null,
  paymentAccounts: [],
  paymentClients: [],
  beneficiaries: [],
  beneficiariesLoading: false,
  paymentSaving: false,
  paymentInfoLoading: false,
  beneficiaryEditing: false,
  xs2aInfoIsLoading: false,
  xs2aFinancialInstitutions: [],
  aiarInfoIsLoading: false,
  aiarInfo: null,
  createAiarIsLoading: false,
  createAiarAuthorizationIsLoading: false,
  aiarAuthorization: null,
  aiarAuthorizationError: false,
  createIsabelAuthorizationIsLoading: false,
  isabelAuthorizationError: false,
};
