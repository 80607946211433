import { inject }      from '@angular/core';
import {
  CanActivateFn,
  Router,
}                      from '@angular/router';
import { ENVIRONMENT } from '../app.constants';

export const environmentGuard: CanActivateFn = (route) => {
  const router = inject(Router);
  const currentEnvironment = inject(ENVIRONMENT).name;
  const allowedEnvironment = route.data['environment'] as string;

  if (currentEnvironment !== allowedEnvironment) {
    router.navigate(['/']);
    return false;
  }
  return true;
};
