<div class="flex flex-col gap-[0.5rem] h-full">
  <h2 *ngIf="title || icon" class="flex items-center gap-x-[1rem]">
    <mat-icon *ngIf="icon" [svgIcon]="icon" class="icon-20 icon-brand"></mat-icon>
    <div *ngIf="title">{{ title }}<span *ngIf="required" class="text-brand"> *</span></div>
    <mat-spinner *ngIf="isLoading" class="spinner-brand" diameter="15"></mat-spinner>
  </h2>
  <div [class.bg-secondary]="background"
       class="{{ border ? 'rounded-[0.5rem] border p-[1rem]' : '' }}
              {{ error ? 'border-negative/50' : 'border-light' }}
              h-full
              {{ scroll ? 'overflow-scroll' : '' }} ">
    <ng-content></ng-content>
  </div>
</div>
