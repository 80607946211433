import { Injectable } from '@angular/core';
import { Store }      from '@ngrx/store';
import {
  closeIsabelRedirectDialog,
  createIsabelAuthorization,
}                     from '../state/app.actions';
import {
  selectCreateIsabelAuthorizationIsLoading,
  selectIsabelAuthorizationError,
}                     from '../state/app.selectors';

@Injectable({
  providedIn: 'root',
})
export class IsabelService {
  createIsabelAuthorizationIsLoading$ = this.store.select(selectCreateIsabelAuthorizationIsLoading);
  isabelAuthorizationError$ = this.store.select(selectIsabelAuthorizationError);

  constructor(private readonly store: Store) {
  }

  createIsabelAuthorization(code: string) {
    this.store.dispatch(createIsabelAuthorization({ code }));
  }

  closeIsabelRedirectDialog() {
    this.store.dispatch(closeIsabelRedirectDialog());
  }
}
