<rb-dialog (buttonClick)="onDialogButtonClick()" [config]="dialogConfig">
  <div *ngIf="(createIsabelAuthorizationIsLoading$ | async) === false" class="flex flex-col gap-y-[4rem] items-center">
    <div class="flex flex-col items-center gap-y-[1rem] w-full">
      <ng-container *ngIf="isabelError">
        <mat-icon class="icon-40 icon-negative" svgIcon="cross"></mat-icon>
        <div class="font-bold">{{ 'APP.ISABEL_REDIRECT.ISABEL_ERROR.TITLE' | translate }}</div>
      </ng-container>
      <ng-container *ngIf="(isabelAuthorizationError$ | async) === true">
        <mat-icon class="icon-40 icon-negative" svgIcon="cross"></mat-icon>
        <div class="font-bold">{{ 'APP.ISABEL_REDIRECT.AUTH_ERROR.TITLE' | translate }}</div>
      </ng-container>
      <ng-container *ngIf="!isabelError && (isabelAuthorizationError$ | async) === false">
        <mat-icon class="icon-40 icon-brand" svgIcon="check"></mat-icon>
        <div class="font-bold">{{ 'APP.ISABEL_REDIRECT.CONNECTED.TITLE' | translate }}</div>
      </ng-container>
    </div>
    <rb-form-section [background]="false" class="h-[40rem] w-full">
      <ng-container *ngIf="isabelError">
        <div>{{ 'APP.ISABEL_REDIRECT.ISABEL_ERROR.NO_AUTH' | translate }}</div>
        <div class="mt-[4rem]">{{ 'APP.ISABEL_REDIRECT.ISABEL_ERROR.INFO' | translate }}</div>
        <div class="font-bold mt-[2rem]">{{ 'APP.ISABEL_REDIRECT.ISABEL_ERROR.ERROR' | translate }}</div>
        <div>{{ isabelError }}</div>
        <ng-container *ngIf="isabelErrorDescription">
          <div
            class="font-bold mt-[2rem]">{{ 'APP.ISABEL_REDIRECT.ISABEL_ERROR.ERROR_DESCRIPTION' | translate }}
          </div>
          <div>{{ isabelErrorDescription }}</div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="(isabelAuthorizationError$ | async) === true">
        <div class="whitespace-pre-wrap">{{ 'APP.ISABEL_REDIRECT.AUTH_ERROR.INFO' | translate }}</div>
      </ng-container>
      <ng-container *ngIf="!isabelError && (isabelAuthorizationError$ | async) === false">
        <div>{{ 'APP.ISABEL_REDIRECT.AUTHORIZED.SYNCING' | translate }}</div>
        <div class="flex gap-x-[0.5rem] items-center mt-[4rem] text-sm">
          <mat-icon class="icon-15 icon-warning" svgIcon="warning"></mat-icon>
          <div class="font-bold">{{ 'APP.ISABEL_REDIRECT.AUTHORIZED.IMPORTANT.TITLE' | translate }}</div>
        </div>
        <ul class="ml-[0.5rem] mt-[0.5rem] text-sm">
          <li>{{ 'APP.ISABEL_REDIRECT.AUTHORIZED.IMPORTANT.INFO_1' | translate }}</li>
          <li>{{ 'APP.ISABEL_REDIRECT.AUTHORIZED.IMPORTANT.INFO_2' | translate }}</li>
        </ul>
      </ng-container>
    </rb-form-section>
  </div>
  <div *ngIf="(createIsabelAuthorizationIsLoading$ | async) === true" class="flex items-center h-[51rem]">
    <div class="flex flex-col items-center gap-y-[1rem] w-full">
      <mat-spinner diameter="40"></mat-spinner>
      <div class="font-bold">{{ 'APP.ISABEL_REDIRECT.LOADING' | translate }}</div>
    </div>
  </div>
</rb-dialog>
