import { inject }   from '@angular/core';
import {
  CanActivateFn,
  Router,
}                   from '@angular/router';
import {
  ACCESS_TOKEN,
  LOCAL_STORAGE,
  LOGIN_REDIRECT_URL,
}                   from '../app.constants';
import { AppRoute } from '../app.route.enum';

export const loggedInGuard: CanActivateFn = (_route, state) => {
  const localStorage = inject(LOCAL_STORAGE);
  const router = inject(Router);

  const loggedIn = !!localStorage.getItem(ACCESS_TOKEN);
  if (!loggedIn) {
    router.navigate([AppRoute.Login], { state: { [LOGIN_REDIRECT_URL]: state.url } });
  }
  return loggedIn;
};
