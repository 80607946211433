import { inject }            from '@angular/core';
import {
  CanActivateFn,
  Router,
}                            from '@angular/router';
import { Store }             from '@ngrx/store';
import {
  map,
  of,
  switchMap,
  tap,
}                            from 'rxjs';
import { UserRole }          from '~domain/enums';
import { UserApiService }    from '../core';
import { selectAccountUser } from '../state/app.selectors';

export const roleGuard: CanActivateFn = (route) => {
  const router = inject(Router);
  const store: Store = inject(Store);
  const userApiService = inject(UserApiService);

  const role = route.data['role'] as UserRole;
  return store.select(selectAccountUser).pipe(
    switchMap(user => user ? of(user) : userApiService.fetchAccountUser()),
    map(user => user.roles.includes(role)),
    tap(canActivate => {
      if (!canActivate) {
        router.navigate(['/']);
      }
    }),
  );
};
