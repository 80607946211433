import {
  Inject,
  Injectable,
}                           from '@angular/core';
import { Store }            from '@ngrx/store';
import {
  ACCESS_TOKEN,
  LOCAL_STORAGE,
}                           from '../app.constants';
import { clearAccountUser } from '../state/app.actions';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {

  constructor(
    @Inject(LOCAL_STORAGE) private readonly localStorage: Storage,
    private readonly store: Store,
  ) {
  }

  logout(): void {
    this.localStorage.removeItem(ACCESS_TOKEN);
    this.store.dispatch(clearAccountUser());
  }
}
