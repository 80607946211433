import { Injectable }           from '@angular/core';
import { Params }               from '@angular/router';
import { Store }                from '@ngrx/store';
import { FinancialInstitution } from '~domain/types';
import {
  clearAiarInfo,
  closeXs2aRedirectDialog,
  createAiar,
  createAiarAuthorization,
  fetchAiarInfo,
}                               from '../state/app.actions';
import {
  selectAiarAuthorization,
  selectAiarAuthorizationError,
  selectAiarInfo,
  selectAiarInfoIsLoading,
  selectCreateAiarAuthorizationIsLoading,
  selectCreateAiarIsLoading,
  selectXs2aFinancialInstitutions,
}                               from '../state/app.selectors';
import {
  authModelImportantMessagesConfig,
  authModelInstructionsConfig,
  financialInstitutionImportantMessagesConfig,
  financialInstitutionInstructionsConfig,
}                               from './xs2a-config';

@Injectable({
  providedIn: 'root',
})
export class Xs2aService {
  financialInstitutions$ = this.store.select(selectXs2aFinancialInstitutions);
  aiarInfoIsLoading$ = this.store.select(selectAiarInfoIsLoading);
  aiarInfo$ = this.store.select(selectAiarInfo);
  createAiarIsLoading$ = this.store.select(selectCreateAiarIsLoading);
  createAiarAuthorizationIsLoading$ = this.store.select(selectCreateAiarAuthorizationIsLoading);
  aiarAuthorization$ = this.store.select(selectAiarAuthorization);
  aiarAuthorizationError$ = this.store.select(selectAiarAuthorizationError);

  constructor(private readonly store: Store) {
  }

  getInstructions(financialInstitution: FinancialInstitution) {
    return financialInstitutionInstructionsConfig.get(financialInstitution.bic)
      ?? authModelInstructionsConfig.get(financialInstitution.authorizationModel)
      ?? [];
  }

  getImportantMessages(financialInstitution: FinancialInstitution) {
    return financialInstitutionImportantMessagesConfig.get(financialInstitution.bic)
      ?? authModelImportantMessagesConfig.get(financialInstitution.authorizationModel)
      ?? [];
  }

  fetchAiarInfo(financialInstitutionId: string) {
    this.store.dispatch(fetchAiarInfo({ financialInstitutionId }));
  }

  clearAiarInfo() {
    this.store.dispatch(clearAiarInfo());
  }

  createAiar(financialInstitutionId: string, forImport: boolean, customerReference?: string, ibans?: string[]) {
    this.store.dispatch(createAiar({ financialInstitutionId, forImport, customerReference, ibans }));
  }

  createAiarAuthorization(queryParams: Params) {
    this.store.dispatch(createAiarAuthorization({ queryParams }));
  }

  closeXs2aRedirectDialog() {
    this.store.dispatch(closeXs2aRedirectDialog());
  }
}
