import { CommonModule }       from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
}                             from '@angular/core';
import { MatButtonModule }    from '@angular/material/button';
import { MatIconModule }      from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTabsModule }      from '@angular/material/tabs';
import { RouterModule }       from '@angular/router';
import { TranslateModule }    from '@ngx-translate/core';
import { Observable }         from 'rxjs';

export type Tab = {
  label: string;
  route$: Observable<string>;
  disabled?: boolean;
  hidden$?: Observable<boolean>;
  isLoading$: Observable<boolean>;
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    TranslateModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinner,
  ],
  selector: 'rb-tabs',
  templateUrl: './tabs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent {
  @Input()
  tabs?: Tab[] | null = null;
}
