import { inject }  from '@angular/core';
import {
  CanActivateFn,
  Router,
}                  from '@angular/router';
import { Store }   from '@ngrx/store';
import {
  map,
  of,
  switchMap,
  tap,
}                  from 'rxjs';
import { Feature } from '~domain/enums';
import {
  OfficeApiService,
  UserApiService,
}                  from '../core';
import {
  selectAccountUser,
  selectOffice,
}                  from '../state/app.selectors';

export const featureGuard: CanActivateFn = (route) => {
  const router = inject(Router);
  const store: Store = inject(Store);
  const officeApiService = inject(OfficeApiService);
  const userApiService = inject(UserApiService);

  const feature = route.data['feature'] as Feature;
  const user$ = store.select(selectAccountUser).pipe(
    switchMap(user => user ? of(user) : userApiService.fetchAccountUser()),
  );
  return store.select(selectOffice).pipe(
    switchMap(
      office => office
        ? of(office)
        : user$.pipe(switchMap(user => officeApiService.fetchOffice(user.officeId))),
    ),
    map(office => office.features.includes(feature)),
    tap(canActivate => {
      if (!canActivate) {
        router.navigate(['/']);
      }
    }),
  );
};
